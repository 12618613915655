import React, { useState } from "react";
import "./mainLayout.scss";
import Countdown from "react-countdown";
import { dateDiffMilliSeconds } from "../../utils/utils.js";
import { isMobile } from "react-device-detect";
import { useGlobalState } from "../../GlobalStates.js";
import CloseIcon from "@mui/icons-material/Close";
import useIsAndroid from "../../customHooks/useIsAndroid.js";
import { DownloadAppModal } from "../DownloadAppModal.js";
import { useNavigate, useLocation } from "react-router-dom";

const DownloadAppFooter = ({ children }) => {
  const isInApp = window.isInApp;
  const [showAppHeader, setShowAppHeader] = useState(true);
  const [openDownloadAppModal, setOpenDownloadAppModal] = useState(false);
  const location = useLocation();

  const isAndroid = useIsAndroid();

  const handleDownloadClick = () => {
    if (isMobile) {
      const appStoreRedirect = isAndroid
        ? "https://play.google.com/store/apps/details?id=com.sotravel.sotravel"
        : "https://apps.apple.com/app/id6447177449";
      window.open(appStoreRedirect);
      setShowAppHeader(false);
    } else {
      setOpenDownloadAppModal(true);
    }
  };

  const shouldShowAppHeader = () => {
    if (
      (location.pathname == "/" ||
        location.pathname.toLowerCase().includes("/all-trips")) &&
      showAppHeader &&
      !isInApp
    ) {
      return true;
    }
  };

  if (shouldShowAppHeader())
    return (
      <footer
        style={{
          height: isMobile ? "5rem" : "6rem",
          width: "100%",
          backgroundColor: "rgba(234, 234, 234, 1)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "fixed",
          bottom: 0,
          zIndex: 99999,
        }}
      >
        <CloseIcon
          style={{
            position: "absolute",
            top: 5,
            right: 5,
            color: "black",
          }}
          onClick={() => setShowAppHeader(false)}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              width: "60%",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              height: "5rem",
            }}
          >
            <img
              src="/new_logo/app-icon.png"
              style={{
                height: "3.7rem",
                width: "3.7rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "15%",
              }}
            />

            <div
              style={{
                width: "10rem",
                height: "5rem",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
                marginLeft: "12px",
              }}
            >
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: "900",
                }}
              >
                SOTRAVEL APP
              </span>
              <span
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                }}
              >
                Get access to free reservations and deals
              </span>
            </div>
          </div>
          <button
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "deepskyblue",
              color: "white",
              padding: "0.5rem 0.8rem",
              fontSize: "14px",
              fontWeight: "900",
            }}
            onClick={handleDownloadClick}
          >
            DOWNLOAD
          </button>
        </div>

        {openDownloadAppModal && (
          <DownloadAppModal
            open={openDownloadAppModal}
            handleClose={() => {
              setOpenDownloadAppModal(false);
              setShowAppHeader(false);
            }}
          />
        )}
      </footer>
    );
};

export default DownloadAppFooter;
