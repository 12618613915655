export const telegramURL = "https://t.me/sotravel_sg";

export const baseURL =
  process.env.REACT_APP_BASE_URL || "http://localhost:8080";

export const isProd = process.env.NODE_ENV === "production";

export const telegramBotUsername = isProd
  ? "sotravel_bot"
  : process.env.REACT_APP_TELEGRAM_BOT_USERNAME;

export const larryTheMascotTelegramUrl = "https://t.me/sotravel_official";

export const FIREBASE_AUTH_TOKEN_KEY = "FIREBASE_AUTH_TOKEN";

export const seoDescription =
  "We bring you on group adventures to build friendships that last a lifetime.";
