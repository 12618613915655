import unauthAxios, { unauthAxiosFetcher } from "../axios";
import authAxios, { authAxiosFetcher } from "../authAxios";
import useSWR from "swr";

export const upsertNoticationAuthRequired = (
  trip_id,
  project_id,
  notify,
  soldOut = false
) =>
  authAxios.post("/v1/notifications/upsertNoticationAuthRequired", {
    trip_id: trip_id,
    project_id: project_id,
    notify: notify,
    soldOut: soldOut,
  });

export const upsertWebinarNoticationAuthRequired = (
  webinar_id,
  project_id,
  invite_link
) =>
  authAxios.post("/v1/notifications/upsertWebinarNoticationAuthRequired", {
    webinar_id,
    project_id,
    invite_link,
  });

export const upsertNoticationNoAuth = (trip_id, project_id, user_id) =>
  unauthAxios.post("/v1/notifications/upsertNoticationNoAuth", {
    trip_id: trip_id,
    project_id: project_id,
    user_id: user_id,
  });

export const sendGenericMessage = async (message) =>
  await authAxios.post("/v1/notifications/sendGenericMessage", {
    message: message,
  });
