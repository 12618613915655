import { createGlobalState } from "react-hooks-global-state";

const initialState = {
  user: { credits: 0 },
  userLoading: true,
  showProfile: false,
  openGroupDetails: false,
  openLogin: false,
  openAlertBannerMsg: "",
  sortByHome: "dates",
  promotion: null,
  searchedTrips: [],
  prevSearchMonths: "",
  prevSearchContinents: "",
};

export const { useGlobalState } = createGlobalState(initialState);
